import React, { useState , useEffect } from 'react';
import http from '../utils/http';
import './JobAdd.css';
import ArrowIcon from '../Assets/Arrow.svg';
import { useNavigate } from 'react-router-dom';

function JobAdd() {
    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [jobStatus, setJobStatus] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [jobImage, setJobImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [payment, setPayment] = useState('');
    const [districts, setDistricts] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedArea, setSelectedArea] = useState('');

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [jobError, setJobError] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token not found');
                return;
            }
    
            // Set loading state to true to indicate form submission is in progress
            setLoading(true);
    
            const formData = new FormData();
            formData.append('job_title', jobTitle);
            formData.append('job_description', jobDescription);
            formData.append('job_status', jobStatus);
            formData.append('job_image', jobImage);
            formData.append('contact_number', contactNumber);
            formData.append('payment', payment);
            formData.append('district_id', selectedDistrict);
            formData.append('area_id', selectedArea);
    
            const response = await http.post('job/create-new', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            //console.log(response.data); // Log the response from the backend
            // Clear form fields after successful submission
            setJobTitle('');
            setJobDescription('');
            setJobStatus('');
            setContactNumber('');
            setJobImage(null);
            setPayment('');
            setSelectedDistrict('');
            setSelectedArea('');
            setErrorMessage('');
            setSuccess('Job created successfully');
            setErrors(false);

        } catch (error) {
            console.error('Error creating job:', error.response.data);
            setErrorMessage(error.response.data.message || 'Failed to create job');
            setErrors(error.response.data.errors || {});
        } finally {
            // Set loading state to false after form submission
            setLoading(false);
        }
    };

    //getting all districts
    useEffect(() => {
        fetchDistricts();
    }, []);

    const fetchDistricts = async () => {
        try {
            const response = await http.get('location/get-all-districts'); 
            setDistricts(response.data.data);
        } catch (error) {
            console.error('Error fetching districts:', error);
        }
    };

    const handleDistrictChange = async (e) => {
        const selectedDistrictId = e.target.value;
        setSelectedDistrict(selectedDistrictId);
        try {
            const response = await http.get(`location/get-all-areas-by-district?district_id=${selectedDistrictId}`);
            setAreas(response.data.data);
        } catch (error) {
            console.error('Error fetching areas:', error);
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
    
        if (!file) return; // Handle no file selected
    
        if (file.size > 1024 * 1024) {
            setJobError(true)
          return;
        }
    
        setJobImage(file);
      };

      useEffect(() => {
        if (jobError) {
          const timeoutId = setTimeout(() => setJobError(false), 3400);
          return () => clearTimeout(timeoutId); // Clear timeout on unmount
        }
      }, [jobError]);

      const handleButtonClick = () => {
        // Simulate click on hidden file input element using ref
        fileInputRef.current.click();
      };
    
      const fileInputRef = React.createRef(null);

    return (
        <div className='jobadd-main'>
            <div className='jobadd-iner'>
                <div className='jobadd-back' onClick={() => navigate(-1)}>
                    <img src={ArrowIcon} alt="" />
                    <p>Back</p>
                </div>
                <h2 className='jobadd-heading'>Create New Job</h2>
                
                <form onSubmit={handleSubmit}>
                    <label className='jobadd-input'>
                        Job Title :<br/>
                        <input 
                            className='form-input jobadd-form' 
                            type="text" placeholder='Job Title'
                            value={jobTitle} 
                            onChange={(e) => setJobTitle(e.target.value)} 
                            required 
                            maxlength="70"
                        />
                    </label>
                    <br />
                    <label className='jobadd-input'>
                        Job Description :<br/>
                        <textarea 
                            className='form-input jobadd-form' 
                            placeholder='Job Description' 
                            value={jobDescription} 
                            onChange={(e) => setJobDescription(e.target.value)} 
                            required 
                            rows='7'
                            maxlength="600"
                        />
                    </label>
                    <br />
                    <label className='jobadd-input'>
                        Contact Number :<br/>
                        <input className='form-input jobadd-form' type="text" placeholder='Contact Number' value={contactNumber} onChange={(e) => setContactNumber(e.target.value)}
                         maxLength="10"
                         />
                    </label>

                    <br/>

                    <label className='jobadd-input'>
                        {jobImage && (
                            <img
                            src={URL.createObjectURL(jobImage)}
                            alt="Job preview"
                            className="job-img"
                            onError={(e) => {
                                e.target.style.display = 'none'; // Hide image if error occurs
                                alert('Error creating image preview.');
                            }}
                            />
                        )}
                        Job Image :<br />
                        (Recommended size 1200 x 854) <br/>
                        <button type="button" className="custom-file-button form-input jobadd-form mb" onClick={handleButtonClick}
                        >
                            Choose File
                        </button>
                        <input
                            type="file"
                            accept="image/*"
                            className='form-input jobadd-form'
                            onChange={handleImageChange}
                            required
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                        {jobError && <p className="job-error-massage">
                            Image size exceeds limit (1024 KB).<br/>
                            Please choose a smaller image.               
                        </p>} 
                    </label>

                    <br />

                    <label className='jobadd-input'>
                        District :<br/>
                        <select 
                            className='form-input jobadd-form' 
                            onChange={handleDistrictChange} 
                            value={selectedDistrict}
                        >
                            <option value="">Select District</option>
                            {districts.map(district => (
                                <option key={district.district_id} value={district.district_id} style={{ color: 'black' }}>
                                    {district.district_name}
                                </option>
                            ))}
                        </select>
                    </label>
                    <br/>
                    <label className='jobadd-input'>
                        Area :<br/>
                        <select 
                            className='form-input jobadd-form'
                            value={selectedArea}
                            onChange={(e) => setSelectedArea(e.target.value)}
                        >
                            <option value="">Select Area</option>
                            {areas.map(area => (
                                <option key={area.area_id} value={area.area_id} style={{ color: 'black' }}>
                                    {area.area_name}
                                </option>
                            ))}
                        </select>
                    </label>

                    <br/>

                    <label className='jobadd-input'>
                        Price (LKR) :<br/>
                        <input className='form-input jobadd-form' type="text" placeholder='Rs.' value={payment} onChange={(e) => setPayment(e.target.value)} />
                    </label>

                    <br/>

                    <label className='jobadd-input'>
                        Job Status :<br/>
                        <select className='form-input jobadd-form' value={jobStatus} onChange={(e) => setJobStatus(e.target.value)}>
                            <option value="" style={{ color: 'black' }}>Please Select Job Status</option>
                            <option value="Active" style={{ color: 'black' }}>Active</option>
                            <option value="Inactive" style={{ color: 'black' }}>Inactive</option>
                        </select>
                    </label>

                    <br/>

                    <button className='btn' type="submit" disabled={loading}>
                        {loading ? 'Creating...' : 'Create Job'}
                    </button>

                    {/* {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    {success && <p className="success-message-dash">Job Created Successfully</p>} */}

                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    {success && <p style={{ color: 'green' }}>{success}</p>}
                    {errors && Object.keys(errors).map(key => (
                        <p key={key} style={{ color: 'red' }}>{errors[key]}</p>
                    ))}

                </form>
            </div>
        </div>
    );
}

export default JobAdd;
