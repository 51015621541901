import React, {useEffect, useRef} from 'react'
import emailjs from '@emailjs/browser';
import Nav from '../Components/Nav'
import EmailImg from '../Assets/ic_baseline-email.svg'
import PhoneImg from '../Assets/ph_phone-fill.svg'
import './ContactUs.css'
import Footer from '../Components/Footer'

const ContactUs = () => {
    useEffect(() =>{
        window.scrollTo(0, 0)
    }, [])

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_zrbtsmt', 'template_7ytj46l', form.current, {
            publicKey: '9VDy9yDevWyhpZMps',
          })
          .then(
            () => {
              console.log('SUCCESS!');
              window.alert("Message sent");
            },
            (error) => {
              console.log('FAILED...', error.text);
              window.alert("Error");
            },
          );
      };

  return (
    <div>
        <Nav/>
        <div className='contact-container'>
            <div className='contact-info-container'>
                <h3 className='contact-header'>Contact Us</h3>
                <div className='contact-info'>
                    <img src={EmailImg} alt="" />
                    <h4>info@softbyte.com</h4>
                </div>
                <div className='contact-info'>
                    <img src={PhoneImg} alt="" />
                        <h4>076 301 5885</h4>
                </div>
                <div className='contact-info'>
                    <img src={PhoneImg} alt="" />
                        <h4>076 754 4868</h4>
                </div>
            </div>

            <hr className='contact-hr'></hr>
            
            <form action="" className='contact-form-container' ref={form} onSubmit={sendEmail}>
                <input type='text' name='user_name' className='form-input' placeholder='Name'/> <br/>
                <input type='text' name='user_email' className='form-input' placeholder='Email'/> <br/>
                <textarea name="message" id="" cols="30" rows="7" className='form-input' placeholder='Message'/>
                <input type="submit" value="Submit" className='btn contact-btn'/>
            </form>

        </div>
        <Footer/>
    </div>
  )
}

export default ContactUs