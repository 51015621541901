import React, { useRef } from 'react';
import './Popup_register.css';
import { RiCloseCircleFill } from "react-icons/ri";
import { NavLink } from 'react-router-dom';

function Popup({onClose}) {

  const modelref = useRef();

  const closeModal =(e) =>{
    if(modelref.current === e.target){
      onClose();
    }
  }

  return (
        <div ref={modelref}  onClick={closeModal} className='fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm popup-main'>
          <div className='pop-up'>
            <div className='pop-up-cancel'>
              <RiCloseCircleFill size="1.3em" color='#FFFFFF' onClick={onClose} />
            </div>
            <div className='pop-up-text'>
              <p className='pop-up-p'>Choose account type</p>
              <NavLink className='nav-button' to="/register/jobseeker"><button className='pop-up-text-button'>Job Seeker</button></NavLink>
              <NavLink className='nav-button' to="/register/employee"><button className='pop-up-text-button'>Job Giver</button></NavLink>
            </div>
          </div>
        </div>
        
  );
}

export default Popup;
