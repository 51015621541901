import React from 'react';
import './Footer.css';
import WP from '../Assets/WP.svg';
import FB from '../Assets/FB.svg';
import Insta from '../Assets/Insta.svg';
import SB from '../Assets/SB.svg';
//import WebsiteLogoWithOutBG from '../Assets/Athwala Non-BG Logo.png';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-top-container'>
        <div className='footer-top'>
          <h3 className='footer-top-name'>Athwala.LK</h3>
          {/* <img src={WebsiteLogoWithOutBG} alt="" /> */}
        </div>
      </div>
      <div className='footer-bottom'>
        <p className='social-header'>Connect with us on Social Media:</p>
        <div className='footer-icons'>
          <a href='https://api.whatsapp.com/send?phone=94767544868' target='_blank' rel='noopener noreferrer'>
            <img className='social-icon' src={WP} alt='WhatsApp' />
          </a>
          <a href='https://web.facebook.com/profile.php?id=100094020455273' target='_blank' rel='noopener noreferrer'>
            <img className='social-icon' src={FB} alt='Facebook' />
          </a>
          <a href='https://www.instagram.com/softbyte_solutions/' target='_blank' rel='noopener noreferrer'>
            <img className='social-icon' src={Insta} alt='Instagram' />
          </a>
          <a href='https://softbytesl.com/' target='_blank' rel='noopener noreferrer'>
            <img className='social-icon' src={SB} alt='SoftByte' />
          </a>
        </div>
        <h6>Follow us for updates and news about Athwala.LK</h6>
        <p className='copy-right-title'>&copy; 2024 Athwala.lk. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
