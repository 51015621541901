import React, { useEffect } from 'react'
import './About.css'
import Nav from '../Components/Nav'
import MissionImg from '../Assets/mission.svg'
import VisionImg from '../Assets/vision.svg'
import Irosha from '../Assets/irosha.png'
import Shakuntha from '../Assets/shakuntha.png'
import Matheesha from '../Assets/matheesha.png'
import Vishma from '../Assets/vishma.png'
import SbLogo from '../Assets/sb-logo.png'
import MtLogo from '../Assets/Mt-logo.png'
import Footer from '../Components/Footer'

const About = () => {
    useEffect(() =>{
        window.scrollTo(0, 0)
    }, [])
  return (
    <div>
        <Nav/>
        <div className='about-hero-container'>
            <div className='about-text-container'>
                <h3>About Us</h3>
                <p>At Athwala, powered by SoftByte, we are committed to connecting talented individuals with rewarding opportunities. Our platform serves as a dynamic marketplace where Job Seekers and Job Givers converge, creating a vibrant ecosystem of collaboration and growth.</p>
            </div>
            <div className='about-mv-container'>
                <div className='about-mv'>
                    <img src={VisionImg} alt="" />
                    <h4>Vision</h4>
                    <p>Empowering individuals from diverse backgrounds through a dynamic platform where they find part-time and freelance opportunities. At Athwala, we connect talent with various job postings, including type settings, poster designs, coding tasks, and more. Our vision is to foster a thriving community where everyone can earn income while pursuing their goals.</p>
                </div>
                <div className='about-mv'>
                    <img src={MissionImg} alt="" />
                    <h4>Mission</h4>
                    <p>Empowering individuals with diverse part-time and freelance opportunities, Athwala is on a mission to facilitate income generation while pursuing personal goals. We provide a user-friendly platform for job seekers to find suitable roles effortlessly. Our aim is to build a vibrant community where talent meets opportunity, fostering meaningful connections and positive change.</p>
                </div>
            </div>
        </div>
        <div className='about-team-container'>
            <h3>Team Members</h3>
            <p>These individuals are the driving force behind everything.</p>
            <div className='about-team-card-container'>
                <div className='about-team-card'>
                    <img src={Irosha} alt="" />
                    <h5>Irosha Lakshan</h5>
                    <p>UI designer / Front-End Dev</p>
                </div>

                <div className='about-team-card'>
                    <img src={Shakuntha} alt="" />
                    <h5>Shakuntha Chandimal</h5>
                    <p>Back-End Dev / Cloud Dev</p>
                </div>
                <div className='about-team-card'>
                    <img src={Matheesha} alt="" />
                    <h5>Matheesha Mihinath</h5>
                    <p>UI designer / Front-End Dev</p>
                </div>
                <div className='about-team-card'>
                    <img src={Vishma} alt="" />
                    <h5>Vishma Nipum</h5>
                    <p>Back-End / Front-End Dev</p>
                </div>
            </div>
        </div>
        <div className='about-sb-container'>
            <div className='about-sb-text'>
                <h3>We are SoftByte</h3>
                <p>Welcome to SoftByte, a hub of innovation and opportunity. We're dedicated to driving growth and collaboration through technology. With a focus on user experience and innovation, we're shaping the future of digital connections. Join us on our journey of endless possibilities and unparalleled opportunities.</p>
                <a href="https://softbytesl.com" target='_blank'>
                    <button className='btn sb-btn'>Visit Website</button>
                </a>
            </div>
            <img src={SbLogo} alt="" />
        </div>
        <div className='about-mt-container'>
            <img src={MtLogo} alt="" />
            <div className='about-mt-text'>
                <h3>Backed by Mr.Tech</h3>
                <p>Mahesh Ranawaka, the visionary behind SoftByte, has been instrumental in our development as a mentor and guide. His brand, Mr.Tech, symbolizes excellence and innovation, inspiring us to push boundaries.</p>
                <a href="https://mrtech.lk" target='_blank'>
                    <button className='btn sb-btn'>Visit Website</button>
                </a>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default About