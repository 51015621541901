import React, { useRef, useState, useEffect } from "react";
import { faCheck, faTimes, faInfoCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './RegisterJobSeeker.css'
import './Button.css';
import { useNavigate } from 'react-router-dom';
import http from "../utils/http";

const USER_REGEX = /^[A-Za-z][A-Za-z0-9_-]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,24}$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const RegisterJobSeeker = () => {
    useEffect(() =>{
        window.scrollTo(0, 0)
    }, [])

    const userRef = useRef(null);
    const errRef = useRef();
    const navigate = useNavigate();


    const [firstName, setFirstName] = useState('');
    const [firstNameCount, setFirstNameCount] = useState(0);
    const  maxFirstNameLength = 24;

    const [lastName, setLastName] = useState('');
    const [lastNameCount, setLastNameCount] = useState(0);
    const  maxLastNameLength = 24;
    
    const [nic, setNic] = useState('');
    const [nicCount, setNicCount] = useState(0);
    const  maxNicLength = 24;

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberCount, setPhoneNumberCount] = useState(0);
    const  maxPhoneNumberLength = 10;

    const [faculty, setFaculty] = useState('');
    const [facultyCount, setFacultyCount] = useState(0);
    const  maxFacultyLength = 32;

    const [profession, setProfession] = useState('');
    const [professionCount, setProfessionCount] = useState(0);
    const  maxProfessionLength = 24;

    const [user, setUser] = useState('');
    const [usernameLength, setUsernameLength] = useState(0);
    const maxUsernameLength = 24;

    const [email, setEmail] = useState('');
    const [validName, setValidName] = useState(false);
    const [emailLength, setEmailLength] = useState(0);
    const maxEmailLength = 60;

    const [userFocus, setUserFocus] = useState(false);
    const [userAvailable, setUserAvailable] = useState(true);
    const [emailAvailable, setEmailAvailable] = useState(true)

    const [pwd, setPwd] = useState('');
    const [pwdLength, setPwdLength] = useState(0);
    const maxPwdLength = 24;

    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [showPwd, setShowPwd] = useState(false);
    const [showConfirmPwd, setShowConfirmPwd] = useState(false);
    const [isUniversityStudent, setIsUniversityStudent] = useState(false);
    const [currentUniversity, setCurrentUniversity] = useState('');
    const [currentFaculty, setCurrentFaculty] = useState('');

    const [matchPwd, setMatchPwd] = useState('');
    const [matchPwdCount, setMatchPwdCount] = useState(0);
    const maxMatchPwdLength = 24;

    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    //const [profilePicture, setProfilePicture] = useState(null);

    useEffect(() => {
        if (userRef.current && userRef.current.focus) {
            userRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user]);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd]);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd]);

    useEffect(() => {
        // Check availability when user changes username or email
        checkAvailability(user, email);
    }, [user, email]);

    useEffect(() => {
        if (!isUniversityStudent) {
            setCurrentUniversity('');
            setCurrentFaculty('');
            setFaculty('');
            setFacultyCount(0);
        }
    }, [isUniversityStudent]);


    // Text Characters Limitation for Everything

    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[a-zA-Z\s]*$/);

        if (isValid) {
            setFirstName(value);
            setFirstNameCount(value.length);
        }
    };

    const handleLastNameChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[a-zA-Z\s]*$/);

        if (isValid) {
            setLastName(value);
            setLastNameCount(value.length);
        }
    }

    const handleNicChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[a-zA-Z0-9\s]*$/);

        if (isValid) {
            setNic(value);
            setNicCount(value.length);
        }
    }

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[0-9]*$/);

        if (isValid) {
            setPhoneNumber(value);
            setPhoneNumberCount(value.length);
        }
    }

    const handleFacultyChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[a-zA-Z\s]*$/);

        if (isValid) {
            setFaculty(value);
            setFacultyCount(value.length);
            if (isUniversityStudent) {
                setCurrentFaculty(value);
            } else {
                setCurrentFaculty('');
            }
        } else {
            // setFaculty('');
            // setFacultyCount(0);
            // setCurrentFaculty('');
        }
    }

    const handleProfessionChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[a-zA-Z\s]*$/);

        if (isValid) {
            setProfession(value);
            setProfessionCount(value.length);
        }
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value;

        if (value.length <= maxUsernameLength) {
            setUser(value);
            setUsernameLength(value.length);
            setErrMsg(""); // Clear any previous error message
        } else {
            setErrMsg("Username exceeds the character limit."); // Inform the user about exceeding the limit
        }
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;

        if (value.length <= maxEmailLength) {
            setEmail(value);
            setEmailLength(value.length);
            setErrMsg("");
        } else {
            setEmailLength(maxEmailLength); //
        }
    }

    const handlePwdChange = (e) => {
        const value = e.target.value;

        if (value.length <= maxPwdLength) {
            setPwd(value);
            setPwdLength(value.length);
            setErrMsg("");
        } else {
            setErrMsg("Password exceeds the character limit.");
        }
    }

    const handleMatchPwdChange = (e) => {
        const value = e.target.value;

        if (value.length <= maxMatchPwdLength) {
            setMatchPwd(value);
            setMatchPwdCount(value.length);
            setErrMsg("");
        } else {
            setErrMsg("Password exceeds the character limit.");
        }
    }

    const validateInput = (value, regex) => {
        return regex.test(value);
    };
    

    const checkAvailability = async (username, email) => {
      // Validate email format
      const validEmailFormat = EMAIL_REGEX.test(email);
      if (!validEmailFormat) {
          setEmailAvailable(false);
          return;
      }

      // Your domain validation logic here
      const validDomain = email.endsWith("@gmail.com") || email.endsWith("@example.com"); // Add more domains as needed
      if (!validDomain) {
          setEmailAvailable(false);
          return;
      }
  
      try {
          const response = await http.get(
              `job-seeker/check-username?username=${username}&email=${email}`
          );
          setUserAvailable(response.data.username_available);
          setEmailAvailable(response.data.email_available);
      } catch (error) {
          console.error("Error checking availability:", error);
          setUserAvailable(false);
          setEmailAvailable(false);
      }
  };

    const handleSubmit = async (e) => {
        setSubmitting(true)
        e.preventDefault();

        // Validate all fields before submitting
        const validUsername = USER_REGEX.test(user);
        const validPassword = PWD_REGEX.test(pwd);
        const validConfirmPassword = pwd === matchPwd;

        if (!validUsername || !validPassword || !validConfirmPassword) {
            setErrMsg("Invalid Entry");
            return;
        }

        // Create a new FormData object
        const formData = new FormData();
        // Append fields to the FormData object
            formData.append('username', user);
            formData.append('password', pwd);
            formData.append('email', email);
            formData.append('seeker_first_name', e.target.elements.firstName.value);
            formData.append('seeker_last_name', e.target.elements.lastName.value);
            formData.append('nic', e.target.elements.nic.value);
            formData.append('contact_number[]', e.target.elements.phoneNumber.value);
            formData.append('seeker_DOB', e.target.elements.dateOfBirth.value);
            formData.append('profession', e.target.elements.profession.value);
            formData.append('university', e.target.elements.cars.value)
            formData.append('faculty', e.target.elements.faculty.value)
            formData.append('profile_image', profilePicture, `profile.${profilePicture.type.split('/')[1]}`);

        console.log("Data being sent:", formData); // Log the data being sent

        try {
            const response = await http.post(
                "job-seeker/add",
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    withCredentials: true
                }
            );
            console.log(response?.data);
            console.log(response?.accessToken);
            console.log(JSON.stringify(response));
            setSuccess(true);
            setSubmitting(false)
            setUser('');
            setEmail('');
            setPwd('');
            setMatchPwd('');
            await new Promise(resolve => setTimeout(resolve, 2000));
            navigate("/login");
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username or Email Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            if (errRef.current && errRef.current.focus) {
                errRef.current.focus();
            }
        }
    }; 


    // const handleImageChange = (imageBlob) => {
    //     setProfilePicture(imageBlob);
    // };


    //new profile picture 
    const [profilePicture, setProfilePicture] = useState(null);
    const [jobError, setJobError] = useState(false);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
    
        if (!file) return; // Handle no file selected
    
        if (file.size > 1024 * 1024) {
            setJobError(true)
          return;
        }
    
        setProfilePicture(file);
      };

    const handleButtonClick = () => {
        // Simulate click on hidden file input element using ref
        fileInputRef.current.click();
    };

    const fileInputRef = React.createRef(null);

    useEffect(() => {
        if (jobError) {
            const timeoutId = setTimeout(() => setJobError(false), 3400);
            return () => clearTimeout(timeoutId); // Clear timeout on unmount
        }
    }, [jobError]);


  return (
    <div className="reg-main">
      <div className="login">
        <h1 className='seeker-title'>Registration</h1>
        <h3 className='sub-seeker-title'>Let’s find you some <span className='highlight'>jobs.</span></h3>
        <form onSubmit={handleSubmit}>
            <div className="profile-pic-container-seeker">

                {/* {profilePicture && (
                    <img 
                        className="profile-pic"
                        src={URL.createObjectURL(profilePicture)}
                        alt="Profile Pic" 
                    />
                )}
                {!profilePicture && <ProfilePictureUpload onImageChange={handleImageChange} />} */}



                {profilePicture && (
                            <img
                            src={URL.createObjectURL(profilePicture)}
                            alt="Profile Pic"
                            className="profile-pic"
                            onError={(e) => {
                                e.target.style.display = 'none'; // Hide image if error occurs
                                alert('Error creating image preview.');
                            }}
                            />
                        )}
                        
                        <button 
                            type="button" 
                            className="custom-file-button  mb" 
                            onClick={handleButtonClick}
                        >
                            Choose a Profile Picture
                        </button>

                        <input
                            type="file"
                            accept="image/*"
                            className='form-input jobadd-form'
                            onChange={handleImageChange}
                            required
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />

                        {jobError && <p className="pic-error-massage">
                            Image size exceeds limit (1024 KB).<br/>
                            Please choose a smaller image.               
                        </p>}


            </div>

            <div className="input-label-with-limit">
                <label htmlFor="username" className='labels'>
                    Username :
                    <FontAwesomeIcon icon={faCheck} className={(validName && userAvailable) ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={(validName && !userAvailable) || !user ? "invalid" : "hide"} />
                </label>
                <span className="character-limit-text">{usernameLength} / {maxUsernameLength}</span>
            </div>
            <input
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                value={user}
                onChange={handleUsernameChange}
                required
                aria-invalid={validName ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setUserFocus(true)}
                onBlur={() => setUserFocus(false)}
                className='text_field'
                placeholder="Username"
                maxLength={maxUsernameLength}
            />
            {!userAvailable && <p className="error-message">Username is already taken.</p>}
            <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                4 to 24 characters.<br />
                Must begin with a letter.<br />
                Letters, numbers, underscores, hyphens allowed.
            </p>

            <div className="input-label-with-limit">
                <label htmlFor="email" className='labels'>
                    Email :
                    <FontAwesomeIcon icon={faCheck} className={(emailAvailable) ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={(!emailAvailable) || !email ? "invalid" : "hide"} />
                </label>
                <span className="character-limit-text">{emailLength} / {maxEmailLength}</span>
            </div>
            <input
                id="email"
                className='text_field'
                type="email"
                placeholder='Email'
                value={email}
                onChange={handleEmailChange}
                required
            />
            {!emailAvailable && <p className="error-message">Invalid email or already taken.</p>}

            
            <div className="input-label-with-limit">
                <label htmlFor="password" className='labels'>
                    Password : 
                    <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                </label>
                <span className="character-limit-text">{pwdLength} / {maxPwdLength}</span> 
            </div>
            <div className="password-field">
                <input
                    type={showPwd? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    onChange={handlePwdChange}
                    value={pwd}
                    required
                    aria-invalid={validPwd? "false" : "true"}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                    className='text_field'
                    autoComplete="current-password"
                />
                <FontAwesomeIcon
                    icon={showPwd? faEyeSlash : faEye}
                    className="toggle-password-seeker"
                    onClick={() => setShowPwd(!showPwd)}
                />
            </div>
            <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                8 to 24 characters.<br />
                Must include uppercase and lowercase letters and a number.<br />
                Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
            </p>


            <div className="input-label-with-limit">
                <label htmlFor="confirm_pwd" className='labels'>
                    Confirm Password :
                    <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                    <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                </label>
                <span className="character-limit-text">{matchPwdCount} / {maxMatchPwdLength}</span>
            </div>
            <div className="password-field">
                <input
                    type={showConfirmPwd? "text" : "password"}
                    id="confirm_pwd"
                    placeholder="Confirm Password"
                    onChange={handleMatchPwdChange}
                    value={matchPwd}
                    required
                    aria-invalid={validMatch? "false" : "true"}
                    aria-describedby="confirmnote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                    className='text_field'
                    autoComplete="current-password"
                />
                {/* Add the eye icon for toggling the visibility */}
                <FontAwesomeIcon
                    icon={showConfirmPwd? faEyeSlash : faEye}
                    className="toggle-password-seeker"
                    onClick={() => setShowConfirmPwd(!showConfirmPwd)}
                />
            </div>
            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                Must match the first password input field.
            </p>

            <hr className='hr'></hr>

            <div className="input-label-with-limit">
                <label htmlFor="firstName" className='labels'>First Name :</label><br />
                <span className="character-limit-text">{firstNameCount} / {maxFirstNameLength}</span>
            </div>
            <input 
                id="firstName" 
                className='text_field' 
                type="text" 
                placeholder='First Name'
                value={firstName}
                onChange={handleFirstNameChange}
                maxLength={maxFirstNameLength}
            ></input><br />

            <div className="input-label-with-limit">
                <label htmlFor="lastName" className='labels'>Last Name :</label><br />
                <span className="character-limit-text">{lastNameCount} / {maxLastNameLength}</span>
            </div>
            <input 
                id="lastName" 
                className='text_field' 
                type="text" 
                placeholder='Last Name'
                value={lastName}
                onChange={handleLastNameChange}
                maxLength={maxLastNameLength}
            ></input><br />

            <div className="input-label-with-limit">
                <label htmlFor="NIC" className='labels'>NIC :</label><br />
                <span className="character-limit-text">{nicCount} / {maxNicLength}</span>
            </div>
            <input
                id="nic" 
                className='text_field' 
                type="text" 
                placeholder='NIC'
                value={nic}
                onChange={handleNicChange}
                maxLength={maxNicLength}
            ></input><br />

            <div className="input-label-with-limit">
                <label htmlFor="phoneNumber" className='labels'>Phone number :</label><br />
                <span className="character-limit-text">{phoneNumberCount} / {maxPhoneNumberLength}</span>
            </div>
            <input 
                id="phoneNumber" 
                className='text_field' 
                type="text" 
                placeholder='07X XXXX XXX'
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                maxLength={maxPhoneNumberLength}
            ></input><br />

            <label htmlFor="dateOfBirth" className='labels'>Date of birth :</label><br />
            <input id="dateOfBirth" className='text_field date-input' type="date" placeholder='YYYY / MM / DD'></input><br />

            <hr className='hr'></hr>

            <input
                className='checkbox'
                type='checkbox'
                onChange={(e) => setIsUniversityStudent(e.target.checked)}
            />
            <label className='checkbox-labels'>University Student</label><br />

            <select className='text_field reg-margin30 disabled-field' id="cars" name="cars" disabled={!isUniversityStudent} value={currentUniversity} onChange={(e) => setCurrentUniversity(e.target.value)}>
                <option className='labels-drop' value="" selected>Select University</option>
                <option className='labels-drop' value="University of Colombo">University of Colombo</option>
                <option className='labels-drop' value="Eastern University">Eastern University	</option>
                <option className='labels-drop' value="saUniversity of Jaffna">University of Jaffna	</option>
                <option className='labels-drop' value="University of Kelaniya">University of Kelaniya	</option>
                <option className='labels-drop' value="University of Moratuwa">University of Moratuwa	</option>
                <option className='labels-drop' value="Open University">Open University	</option>
                <option className='labels-drop' value="University of Peradeniya">University of Peradeniya</option>
                <option className='labels-drop' value="Rajarata University">Rajarata University</option>
                <option className='labels-drop' value="University of Ruhuna">University of Ruhuna</option>
                <option className='labels-drop' value="Sabaragamuwa University">Sabaragamuwa University</option>
                <option className='labels-drop' value="South Eastern University">South Eastern University</option>
                <option className='labels-drop' value="University of Sri Jayewardenepura">University of Sri Jayewardenepura</option>
                <option className='labels-drop' value="Uva Wellassa University	">Uva Wellassa University	</option>
                <option className='labels-drop' value="University of the Visual and Performing Arts">University of the Visual and Performing Arts</option>
                <option className='labels-drop' value="Wayamba University">Wayamba University</option>
                <option className='labels-drop' value="Gampaha Wickramarachchi University">Gampaha Wickramarachchi University</option>
                <option className='labels-drop' value="University of Vavuniya">University of Vavuniya</option>
            </select><br />

            <div className="input-label-with-limit">
                <label htmlFor="faculty" className='labels'>Faculty :</label><br />
                <span className="character-limit-text">{facultyCount} / {maxFacultyLength}</span>
            </div>
            <input 
                id="faculty" 
                className='text_field disabled-field' 
                type="text" 
                placeholder='Faculty' 
                disabled={!isUniversityStudent} 
                // value={currentFaculty} onChange={(e) => setCurrentFaculty(e.target.value)}
                value={faculty}
                onChange={handleFacultyChange}
                maxLength={maxFacultyLength}
            ></input><br />

            <div className="input-label-with-limit">
                <label htmlFor="profession" className='labels'>Profession :</label><br />
                <span className="character-limit-text">{professionCount} / {maxProfessionLength}</span>
            </div>
            <input 
                id="profession" 
                className='text_field' 
                type="text" 
                placeholder='Profession'
                value={profession}
                onChange={handleProfessionChange}
                maxLength={maxProfessionLength}
            ></input><br />


            <button 
                className="login_button"
                disabled={!validName || !validPwd || !validMatch || !userAvailable || profilePicture === null}>             
                {submitting ? 'Submitting...' : 'Submit'}
            </button>
            <div className="msg-container">
                {errMsg && <p className="error-message">{errMsg}</p>}
                {success && <p className="success-message">Registration Successful!</p>}
                {profilePicture === null && <p className="error-message">Please upload a profile picture.</p>}
            </div>
            

        </form>
      </div>
    </div>
  )
}

export default RegisterJobSeeker