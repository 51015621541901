import React, { useEffect, useState } from 'react';
import Nav from '../Components/Nav'
import Footer from '../Components/Footer';
import SearchIcon from '../Assets/searchIcon.svg'
import './Jobs.css'
//import FilterIcon from '../Assets/ion_filter.svg'
import './JobCard.css'
import Lottie from 'react-lottie';
import loadingAnimation from '../Assets/Animation - 1713718378766.json';
import { useLocation , Link} from 'react-router-dom';
import { useRef } from 'react';
import LocationIcon from '../Assets/location_icon.png'
import http from '../utils/http';

const Jobs = () => {
  const location = useLocation();
  const [jobs, setJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const initialSearchQuery = useRef('');


  useEffect(() => {
    const storedSearchQuery = location.state?.searchQuery || '';
    setSearchQuery(storedSearchQuery);
    initialSearchQuery.current = storedSearchQuery;
  }, [location.state]);

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        let response;
        if (searchQuery) {
          response = await http.get(`job/search-all?query=${searchQuery}&page=${currentPage}`);
        } else if (initialSearchQuery.current) {
          response = await http.get(`job/search-all?query=${initialSearchQuery.current}&page=${currentPage}`);
        } else {
          response = await http.get(`job/search-all?page=${currentPage}`);
        }
        const activeJobs = response.data.jobs.filter(job => job.job_status === "Active");
        setJobs(activeJobs || []);
        setTotalPages(response.data.totalPages || 1);
        window.scrollTo(0, 0);
      } catch (error) {
        console.error('Error fetching jobs', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [searchQuery, initialSearchQuery.current, currentPage]);

  const handleSearch = (event) => {
    const searchValue = event.target.value.trim();
    setSearchQuery(searchValue);
    setCurrentPage(1);

    // If the search input is empty, reset initialSearchQuery to fetch all jobs
    if (searchValue === '') {
      initialSearchQuery.current = '';
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className='container-of-main-jobs-page'>
        <Nav/>
        <div className='jobs-hero'>
            <div className='jobs-search-container'>
                    <div className='search-icon'>
                        <img src={SearchIcon} alt=''/>
                    </div>
                    <input
                        type="text"
                        className='search-form job-search'
                        placeholder='Search Jobs'
                        value={searchQuery}
                        onChange={handleSearch}
                    />
            </div>
            <div className='advance-job-filter-section'>
                <div className='location-filter'>
                    
                </div>
            </div>
        </div>
        <div className='jobs-main'>
            {loading ? (
                <Lottie options={defaultOptions} height={200} width={200} />
            ) : (
                <div className='jobs-container'>
                    {jobs.map((job, index) => (
                      <Link to={`/job/${job.job_id}`}>
                        <div key={index} className='jobs-job-card'>
                            {/* Customize the card structure here */}
                            <img src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${job.image.image_url}`} alt='Job' className='jobs-job-image' />
                            <div className='jobs-job-details'>
                                <div className='jobs-job-giver'>
                                    <img src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${job.job_giver.user.images.image_url}`} alt='Profile' className='jobs-job-giver-img' />
                                    <div className='jobs-job-giver-details'>
                                        <p>{job.job_giver.user.username}</p>
                                        <div className='jobs-job-giver-details-pro'>
                                            <p>{job.job_giver.profession}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className='jobs-job-info'>
                                    <h3>{job.job_title}</h3>
                                    <div className='jobs-location-with-icon'>
                                        <img src={LocationIcon} alt="" />
                                        <p>{job.district.district_name}, {job.area.area_name}</p></div>
                                    <div className='jobs-payout-and-date'>
                                        <p>
                                            <b>
                                            {job.payment === 'Negotiable' ? job.payment : `Rs.${job.payment}`}
                                            </b>
                                        </p>
                                        <p>{new Date(job.created_at).toLocaleDateString()}</p>
                                        {/* <p><b>Posted Date:</b> {formattedDate}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                      </Link>
                    ))}
                </div>
            )}
        </div>
        <div className='pagination-section'>
          <button className='previous-button-job'
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <span className='page-data-info'>Page {currentPage} of {totalPages}</span>
          <button className='next-button-job'
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
          <div className='space-for-pagination'>
          </div>
        </div>
        <Footer/>
    </div>
  );
};

export default Jobs;