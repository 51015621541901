import React, { useState, useEffect } from 'react';
import edit_button from '../Assets/edit-button.svg';
import './DashBoard_job.css'
import http from '../utils/http';
import Lottie from 'react-lottie';
import loadingAnimation from '../Assets/Animation - 1713718378766.json';
import { Link, NavLink } from 'react-router-dom';

function getClassNameForStatus(status) {
    switch(status) {
        case 'Inactive':
            return 'job-status-pending';
        case 'Active':
            return 'job-status-in-progress';
        case 'Completed':
            return 'job-status-completed';
        case 'Deleted':
            return 'job-status-cancelled';
        default:
            return ''; // default class or no class if status doesn't match
    }
}

const Dashboardjob = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [dropdownStates, setDropdownStates] = useState({});
    const [activeJobsCount, setActiveJobsCount] = useState(0);

    const [jobError, setJobError] = useState(null);
    const [jobUpdating,setJobUpdating] = useState(null);
    const [jobStatus,setJobStatus] = useState(null);
    const [buttonDisabled,setButtonDisabled] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('Token not found');
                    return;
                }
    
                const response = await http.get('job/get-user-all', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setJobs(response.data.jobs);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
    
        fetchData();
    }, [jobs]);

    useEffect(() => {
        function handleClickOutside(event) {
            const isInsideJobCard = event.target.closest('.dashbord-job-cart-delet');
            if (!isInsideJobCard) {
                setDropdownStates({});
            }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const count = jobs.filter(job => job.job_status === 'Active').length;
        setActiveJobsCount(count);
    }, [jobs]);

    const toggleDropdown = (jobId) => {
        setDropdownStates(prevState => ({
            ...prevState,
            [jobId]: !prevState[jobId]
        }));
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    if (loading) {
        return <div className='loading-screen-full'><Lottie options={defaultOptions} height={400} width={400} /></div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const updateJobStatus = async (jobId, newStatus) => {
        try {
          // Disable button to prevent duplicate clicks
          setButtonDisabled(true);

          // Update successful - reset states
          setDropdownStates(prevState => ({
            ...prevState,
            [jobId]: false
          }));
      
          // Count active jobs
          const count = jobs.filter(job => job.job_status === 'Active').length;
      
          // Check if exceeding active job limit
          if (newStatus === 'Active' && count >= 6) {
            setJobStatus(true);
            setTimeout(() => setJobStatus(false), 2000);
            
            setDropdownStates(prevState => ({
              ...prevState,
              [jobId]: false
            }));
            setButtonDisabled(false); // Allow future clicks even on error
            return;
          }
      
          setJobUpdating(true);
          const formData = JSON.stringify({
            job_id: jobId,
            job_status: newStatus
          });
      
          const response = await http.put('job/update-status', formData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });

        setTimeout(() =>  setJobUpdating(false), 1000);
        setTimeout(() =>  setButtonDisabled(false), 1100);      
      
        } catch (error) {
          console.error('Failed to update job status:', error.message);
          setButtonDisabled(false); // Allow clicks even on error
        }
      };
    


    const handleCreateJobClick = async () => {
        setJobError(true);
        //alert('You currently have 6 active jobs. To add more, please consider deleting or deactivating a job.');
        await new Promise(resolve => setTimeout(resolve, 2500));
        setJobError(false);
        
    };

    return (
      <div className='main-dashboard-job'>
        <div  className='dashbord-job'>
        <div className='dashbord-job-fullset'>
            <div className='dashbord-job-head'>
                <p className='dashbord-job-head-p1'>JOBS</p>
                {jobUpdating && <Lottie options={defaultOptions} height={33} width={180} className="status-loading" /> }
                <div className='dashbord-job-head-count'> <p className='dashbord-job-head-p2'>0{jobs.length}</p> </div>
            </div>
            <div className='dashbord-job-carts custom-scrollbar'>
                {jobs.map(job => {
                    const statusClassName = getClassNameForStatus(job.job_status);
                    return (
                        <div key={job.job_id} className='dashbord-job-cart-boddy'>
                            <div className='dashbord-job-cart-texts'>
                                <p className='dashbord-job-cart-date'>{job.job_title}</p>
                                <p className='dashbord-job-cart-date'>{new Date(job.created_at).toLocaleDateString()}</p>
                                <p className='dashbord-job-cart-date'>{job.district.district_name}</p>
                                <p className='dashbord-job-cart-date'>{job.area.area_name}</p>
                                <p className='dashbord-job-cart-date'>{job.payment === 'Negotiable' ? job.payment : `Rs.${job.payment}`}</p>
                                <p className={`dashbord-job-cart-date ${statusClassName}`}>{job.job_status}</p>
                            </div>
                            <div className='dashbord-job-cart-buttns'>
                                <Link to={`/jobedit/${job.job_id}`}>
                                    <div className='dashbord-job-cart-edite'>
                                        <img src={edit_button} className='dashbord-job-cart-svg' alt='' />
                                    </div>
                                </Link>
                                <div className='dashbord-job-cart-delet'>
                                <div className="relative">
                                    <div className="cursor-pointer text-white" onClick={() => toggleDropdown(job.job_id)}>More</div>
                                    {dropdownStates[job.job_id] && (
                                        <ul className="absolute top-full right-0 mt-2 py-2 bg-white rounded-lg shadow-xl z-20" >
                                            <li>
                                            <button 
                                                className="w-full block px-4 py-2 text-gray-800 hover:bg-gray-200"
                                                disabled={buttonDisabled}  // Add disabled attribute with state variable
                                                onClick={() => {
                                                    setButtonDisabled(true);  // Disable button before update
                                                    updateJobStatus(job.job_id, 'Active');
                                                }}
                                                >
                                                Active
                                            </button>
                                            </li>
                                            <li>
                                                <button 
                                                    className="w-full block px-4 py-2 text-gray-800 hover:bg-gray-200"
                                                    onClick={() => updateJobStatus(job.job_id, 'Inactive')}
                                                >
                                                    Inactive
                                                </button>
                                            </li>
                                            <li>
                                                <button 
                                                    className="w-full block px-4 py-2 text-gray-800 hover:bg-gray-200"
                                                    onClick={() => updateJobStatus(job.job_id, 'Deleted')}
                                                >
                                                    Delete
                                                </button>
                                            </li>
                                            <li>
                                                <button 
                                                    className="w-full block px-4 py-2 text-gray-800 hover:bg-gray-200"
                                                    onClick={() => updateJobStatus(job.job_id, 'Completed')}
                                                >
                                                    Completed
                                                </button>
                                            </li>
                                        </ul>
                                    )}
                                </div>

                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="create-job-container">
            
                {activeJobsCount < 6 ? (
                    <NavLink to='/jobadd'>
                        <button className="create-new-job-button">
                            create new job
                        </button> 
                    </NavLink>
                ) : (
                    <button className="create-new-job-button" onClick={handleCreateJobClick} >
                        create new job
                    </button> 
                )}
                {jobError && <p className="job-message">Can't make more jobs</p>}
                {jobStatus && <p className="job-message">Can't Activate more jobs</p>}
        </div>
        </div>
    </div>
        
    </div>
    );
}

export default Dashboardjob;
