import React, { useState , useEffect } from 'react';
import http from '../utils/http';
import './JobAdd.css';
import ArrowIcon from '../Assets/Arrow.svg';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import loadingAnimation from '../Assets/Animation - 1713718378766.json';

function JobEdit() {

    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    //const [jobStatus, setJobStatus] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [jobImage, setJobImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [payment, setPayment] = useState('');
    const [districts, setDistricts] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [job, setJob] = useState(null);

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [errors, setError] = useState({});
    const [jobError, setJobError] = useState(false);

    const navigate = useNavigate();

    const { jobId } = useParams(); 

    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                setLoading(true);
                const response = await http.get(`job/get-specific-by-id?job_id=${jobId}`); 
                const jobData = response.data.job[0]; // Assuming job data is in the first element of the array
                setJob(jobData);
                setJobTitle(jobData.job_title || '');
                setJobDescription(jobData.job_description || '');
                setContactNumber(jobData.job_contact || '');
                setPayment(jobData.payment || '');
                setSelectedDistrict(jobData.area.district_id || '');
                setSelectedArea(jobData.area.area_id || '');
                setJobImage(jobData.image.image_url || null);
                setError(null);
                //console.log("Response data:", response.data);
                handleDistrictChange({ target: { value: jobData.area.district_id } });
                
            } catch (error) {
                setError(error.response.data.message);
                setJob(null);
            } finally {
                setLoading(false);
            }
        };
    
        fetchJobDetails();
    }, [jobId]);

    // for img upload
    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (!file) return; // Handle no file selected

        if (file.size > 1024 * 1024) {
            setJobError(true)
            return;
        }

        setJobImage(file);
    };

    useEffect(() => {
        if (jobError) {
            const timeoutId = setTimeout(() => setJobError(false), 3400);
            return () => clearTimeout(timeoutId); // Clear timeout on unmount
        }
    }, [jobError]);


    //getting all districts
    useEffect(() => {
        fetchDistricts();
    }, []);

    const fetchDistricts = async () => {
        try {
            const response = await http.get('location/get-all-districts'); 
            setDistricts(response.data.data);
        } catch (error) {
            console.error('Error fetching districts:', error);
        }
    };

    const handleDistrictChange = async (e) => {
        const selectedDistrictId = e.target.value;
        setSelectedDistrict(selectedDistrictId);
        try {
            const response = await http.get(`location/get-all-areas-by-district?district_id=${selectedDistrictId}`);
            setAreas(response.data.data);
        } catch (error) {
            console.error('Error fetching areas:', error);
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: loadingAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    
      if (loading) {
        return  <div className='loading-screen-full bg-[#38115B] h-dvh'><Lottie options={defaultOptions} height={400} width={400} /></div>;
      }



      const handleButtonClick = () => {
        // Simulate click on hidden file input element using ref
        fileInputRef.current.click();
      };
    
      const fileInputRef = React.createRef(null);
    
    
    // submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setUpdating(true);
    
            const formData = JSON.stringify({
                job_id: jobId,
                job_title: jobTitle,
                job_description: jobDescription,
                job_contact: contactNumber,
                payment: payment,
                district_id: String(selectedDistrict),
                area_id: String(selectedArea),
            });
    
            // Update job data
            const response = await http.put('job/update-specific-data', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            setSuccess(response.data.message);
            setErrorMessage('');
        } catch (error) {
            setError(error.response.data.message);
            setSuccess('');
        } finally {
            setUpdating(false);
        }
    
        try {
            // Check if a new image is selected
            if (jobImage) {
                // Upload image
                setUpdating(true);
                const formData = new FormData();
                formData.append('job_id', jobId);
                formData.append('job_image', jobImage);
    
                const imageResponse = await http.post('job/update-specific-image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                setSuccess(imageResponse.data.message);
                setErrorMessage('');
                setUpdating(false);
            }
        } catch (error) {
            setError(error.response.data.message);
            setSuccess('');
        }
    
    };

    return (
        <div className='jobadd-main'>
            <div className='jobadd-iner'>
                <div className='jobadd-back' onClick={() => navigate(-1)}>
                    <img src={ArrowIcon} alt="" />
                    <p>Back</p>
                </div>
                <h2 className='jobadd-heading'>Create New Job</h2>
                
                <form  onSubmit={handleSubmit}>
                    <label className='jobadd-input'>
                        Job Title<br/>
                        <input className='form-input jobadd-form' type="text" placeholder='Job Title'value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} required />
                    </label>
                    <br />
                    <label className='jobadd-input'>
                        Job Description<br/>
                        <textarea className='form-input jobadd-form' placeholder='Job Description' value={jobDescription} onChange={(e) => setJobDescription(e.target.value)} required rows='7'/>
                    </label>

                    <br />

                    <label className='jobadd-input'>
                        Contact Number<br/>
                        <input className='form-input jobadd-form' type="text" placeholder='Contact Number' value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                    </label>

                    <br/>

                    <div className="job-img-container">
                        {jobImage && typeof jobImage === 'object' ? (
                            <img
                            src={URL.createObjectURL(jobImage)}
                            alt="Job preview"
                            className="job-img"
                            onError={(e) => {
                                e.target.style.display = 'none'; // Hide image if error occurs
                                alert('Error creating image preview.');
                            }}
                            />
                        ) : (
                            <img
                            src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${jobImage}`}
                            alt=""
                            className="job-img"
                            />
                        )}
                    </div>

                    <label className='jobadd-input'>
                        Job Image:<br/>
                        (Recommended size 1200 x 854) <br/>
                        
                        <button type="button" className="custom-file-button form-input jobadd-form mb" onClick={handleButtonClick}
                        >
                            Choose File
                        </button>
                        <input
                            type="file"
                            accept="image/*"
                            className='form-input jobadd-form'
                            onChange={handleImageChange}
                            required
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                        {jobError && <p className="job-error-massage">
                            Image size exceeds limit (1024 KB).<br/>
                            Please choose a smaller image.               
                        </p>} 

                    </label>

                    <br />

                    <label className='jobadd-input'>
                        District<br/>
                        <select 
                            className='form-input jobadd-form' 
                            onChange={handleDistrictChange} 
                            value={selectedDistrict}
                        >
                            <option value="">Select District</option>
                            {districts.map(district => (
                                <option key={district.district_id} value={district.district_id} style={{ color: 'black' }}>
                                    {district.district_name}
                                </option>
                            ))}
                        </select>
                    </label>
                    <br/>
                    <label className='jobadd-input'>
                        Area<br/>
                        <select 
                            className='form-input jobadd-form'
                            value={selectedArea}
                            onChange={(e) => setSelectedArea(e.target.value)}
                        >
                            <option value="">Select Area</option>
                            {areas.map(area => (
                                <option key={area.area_id} value={area.area_id} style={{ color: 'black' }}>
                                    {area.area_name}
                                </option>
                            ))}
                        </select>
                    </label>

                    <br/>

                    <label className='jobadd-input'>
                        Price (LKR)<br/>
                        <input className='form-input jobadd-form' type="text" placeholder='Rs.' value={payment} onChange={(e) => setPayment(e.target.value)} />
                    </label>

                    <br/>

                    <button className='btn' type="submit" disabled={updating}>
                        {updating ? 'Updating...' : 'Update Job'}
                    </button>

                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    {success && <p style={{ color: 'green' }}>{success}</p>}
                    {errors && Object.keys(errors).map(key => (
                        <p key={key} style={{ color: 'red' }}>{errors[key]}</p>
                    ))}

                </form>
            </div>
        </div>
    );
}

export default JobEdit;
