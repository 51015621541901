//import { BiSolidMap } from "react-icons/bi";
//import { CgArrowLeft } from "react-icons/cg";
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import http from '../utils/http';
import './JobDetails.css';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import ArrowIcon from '../Assets/Arrow.svg'
import LocationIcon from '../Assets/mdi_location.svg'
import Lottie from 'react-lottie';
import loadingAnimation from '../Assets/Animation - 1713718378766.json';



function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  // Add leading zeros if month or day is less than 10
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  return `${year}-${month}-${day}`;
}

function JobDetails() {

  useEffect(() =>{
    window.scrollTo(0, 0)
}, [])
  
  const navigate = useNavigate();

  const { jobId } = useParams(); 
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);
  const [district, setDistrict ] = useState("")
  const [area, setArea ] = useState("")
  const [pay, setPay] = useState("")
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setLoading(true);
        const response = await http.get(`job/get-specific-by-id?job_id=${jobId}`); 
        //console.log("Response data:", response.data); // Log response data
        const formattedJob = { ...response.data.job[0], created_at: formatDate(response.data.job[0].created_at) };
        setJob(formattedJob);
        setError(null);
        setDistrict(response.data.job[0].district)
        setArea(response.data.job[0].area)
        if (response.data.job[0].payment === 'Negotiable') {
          setPay('Negotiable')
        } else {
          setPay('Rs. '+response.data.job[0].payment)
        }
      } catch (error) {
        console.error("Error:", error);
        setJob(null); 
        setError(error.response.data.message);
      } finally {
        setLoading(false);
      }
    };
    // console.log(job.district)
    // const district = job.district
    // console.log(district)
    fetchJobDetails();
    // Check if token exists in localStorage
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, [jobId]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

if (loading) {
    return <div className='loading-screen-full bg-[#38115B] h-dvh pt-52'><Lottie options={defaultOptions} height={400} width={400} /></div>;
}

if (error) {
    return <div>Error: {error}</div>;
}


  return (
    <div>
      <Nav />
      <div className="req-main">
        {error && <p>Error: {error}</p>}
        {job && (
          <div className="req-main1">
            <div className="job-l">
              <div className='req-back'  onClick={() => navigate(-1)}>
                <img src={ArrowIcon} alt='back' className="back-arrow"/>
                Back
              </div>
              <p className='req-p1'>{job.job_title}</p>
              <div className="job-location">
                <img src={LocationIcon} alt="" />
                <div className="job-location-text">
                  <p>{district.district_name},</p>
                  <p>{area.area_name}</p>
                </div>
              </div>
              {/* <p className='req-p2'><BiSolidMap size='4%'/>Colombo </p>   */}
            </div> 
            <div>
              <div className='req-back mobile-back'  onClick={() => navigate(-1)}>
                  <img src={ArrowIcon} alt='back' className="back-arrow"/>
                  Back
              </div>
            <div className="job-image-container1">
              <img src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${job.image.image_url}`} alt="1" className="job-image1" />
            </div>
            </div>
          </div>
        )}
      </div>
      {/* {job && (
        <div className="job-image-container">
            <img src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${job.image.image_url}`} alt="1" className="job-image" />
        </div>
      )} */}
      {job && (
        
        <div className='req-main2'>

          

          <div className='req-second-discrip'>
            <div className='req-second-discrip-part'>
              <p className='req-second-p1'>Payment :</p>
              <p className='req-second-p2'>{pay}</p>
              <p className='req-second-p1'>Job description :</p>
              <p className='req-second-p2'>{job.job_description}</p>
              <p className='req-second-p1'>Contact Number :</p>
              {isLoggedIn ? (
                <p className='req-second-p2'>{job.job_contact}</p>
              ) : (
                <p className='req-second-p2'>Login to view contact number.</p>
              )}
              
              <p>Created At: {job.created_at}</p>
            </div>
            <div className='req-second-discrip-part2'>
              <div className='req-image'>
                <img 
                  src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${job.job_giver.user.images.image_url}`} 
                  alt="1" 
                />
              </div>
              <div className='req-second-discrip-employ'>
                <p className='req-second-discrip-employ-name'>{job.job_giver.giver_first_name} {job.job_giver.giver_last_name}</p>
                <h3 className='req-second-discrip-employ-profetion'>{job.job_giver.profession}</h3>
              </div>
            </div>
            
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default JobDetails;

