import React, { useState , useEffect } from 'react';
import './Login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Popup from '../Components/Popup_register';
import http from '../utils/http';
import { useNavigate } from 'react-router-dom';
import Nav from '../Components/Nav';

const Login = ({ onLogin }) => {

    useEffect(() =>{
        window.scrollTo(0, 0)
    }, [])

    const [popUp, setPopUp] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            setError('Please provide both username and password.');
            return;
        }
        setLoading(true);
        try {
            const response = await http.post('/login', {
                username,
                password,
            });
            setLoading(false);
            if (response.data.status) {
                // Successful login
                setError('');
                onLogin(response.data.token); // Store token in parent component
                localStorage.setItem('token', response.data.token);
                // Redirect to appropriate dashboard based on role
                navigateBasedOnRole();
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            setLoading(false);
            console.error('An error occurred:', error); 
            setError('An error occurred. Please try again later.');
        }
    };
    
    const navigateBasedOnRole = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token not found');
                return;
            }
    
            const response = await http.get('profile', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            const role = response.data?.data?.user?.role_id;
    
            if (role === 2) {
                navigate('/dashboard');
            } else if (role === 3) {
                navigate('/dashboard/user');
            } else {
                console.error('Unknown role:', role);
                // Handle unknown roles here, possibly redirect to a default dashboard
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };
    
    

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
        <Nav />
            <div className="main">
            <div className="form-container">
                <h1 className="heading">Log In</h1>
                <p className="p1">Welcome back to <span className="text-highlight">Athwala.lk</span></p>
                <form onSubmit={handleSubmit}>
                    <input type="text" className="form-input margin30" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} /> <br />
                    <div className="password-input-container">
                        <input type={showPassword ? "text" : "password"} className="form-input" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="password-toggle-icon" onClick={togglePasswordVisibility} />
                    </div>
                    {error && <p className="error-red">{error}</p>}
                    {/* <p className="p2 right-align">Forgot Password?</p> */}
                    <br/>
                    <button type="submit" className="btn" disabled={loading}>{loading ? 'Logging in...' : 'Log in'}</button>
                    <p className="p2" onClick={() => setPopUp(true)}>Not a member? <span className="text-highlight">Register now</span></p>
                </form>
            </div>
            {popUp && <Popup onClose={() => setPopUp(false)} />}
        </div>
        </>
        
    );
}

export default Login;
