import React, { useState, useEffect } from 'react';
import http from '../utils/http';
import { useNavigate } from 'react-router-dom';
import profileIcon from '../Assets/dashboard-profile-photo.svg';
import './DashBoard.css';
import { faCirclePlus, faSquarePen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import Lottie from 'react-lottie';
import loadingAnimation from '../Assets/Animation - 1713718378766.json';

const  SeekerDashboard = () => {

    const [jobSeekerData, setJobSeekerData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [jobSeekerIMG, setJobSeekerIMG] = useState(null)
    const [jobError, setJobError] = useState(false)
    const [updating, setUpdating] = useState(false);

    const navigate = useNavigate();

    const [editMode, setEditMode] = useState({
        firstName: false,
        lastName: false,
        profession: false,
        dob: false,
        email: false,
        nic: false,
        mobile: false,
    });

    //const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        // Fetch data function
        const fetchData = async () => {
          try {
            const token = localStorage.getItem('token'); // Get token from localStorage
            const response = await http.get('job-seeker/get-specific', {
              headers: {
                'Authorization': `Bearer ${token}`, // Pass token in headers
              }
            });

            const jobSeekerData = response.data.user;
            setJobSeekerIMG(jobSeekerData.images.image_url)

            setJobSeekerData(response.data.user); // Assuming user object is under 'user' key

            //console.log(response.data)
            setLoading(false);
          } catch (error) {
            setError(error.response.data.message); // Assuming error message is returned from backend
            setLoading(false);
          }
        };
    
        fetchData(); // Call fetch data function
      }, []);


    //for img
    useEffect(() => {
        if (jobError) {
            const timeoutId = setTimeout(() => setJobError(false), 3400);
            return () => clearTimeout(timeoutId); // Clear timeout on unmount
        }
    }, [jobError]);
    
      // Render loading state
      const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: loadingAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    
      if (loading) {
        return  <div className='loading-screen-full bg-[#38115B] h-dvh'><Lottie options={defaultOptions} height={400} width={400} /></div>;
      }
    
      // Render error state
      if (error) {
        return <div>Error: {error}</div>;
      }

    const handleLogout = async (event) => {
        event.preventDefault(); // Prevent default behavior of anchor tag or form submission
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token not found');
                return;
            }
    
            const response = await http.get('/logout', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            if (response.data.status) {
                localStorage.removeItem('token');
                navigate('/login');
                //console.log('Logged out successfully');
            } else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };
    //toggle editing
    const toggleEditMode = (field) => {
        setEditMode({
            ...editMode,
            [field]: !editMode[field],
        });
    };


    //updating job giver
    const handleUpdateProfile = async (event) => {
        event.preventDefault();
        setUpdating(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token not found');
                return;
            }
    
            const updatedData = {
                email: document.getElementById('email').value,
                nic: document.getElementById('nic').value,
                seeker_first_name: document.getElementById('seeker_first_name').value,
                seeker_last_name: document.getElementById('seeker_last_name').value,
                profession: document.getElementById('profession').value,
                seeker_DOB: document.getElementById('seeker_DOB').value,
            };
    
            //console.log('Data sent to backend:', updatedData);
    
            const response = await http.put('job-seeker/update', updatedData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (response.data.status === 200) {
                //console.log('Profile updated successfully');
                // setSuccess(true);
                // await new Promise(resolve => setTimeout(resolve, 3000));
                // setSuccess(false);
            } else {
                console.error(response.data.message);
            }      
        } catch (error) {
            console.error('An error occurred:', error);
        }

        // Update contact number
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token not found');
                return;
            }
            
            const contactNumber = document.getElementById('mobile').value;
            const contactData = { contact_number: [contactNumber] };
            
            const contactResponse = await http.post('job-seeker/update-phone', contactData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
        
            if (contactResponse.data.status === 200) {
                //console.log('Contact number updated successfully');
                // setSuccess(true);
                // await new Promise(resolve => setTimeout(resolve, 3000));
                // setSuccess(false);
            } else {
                console.error('Failed to update contact number:', contactResponse.data.message);
            }
        } catch (error) {
            console.error('Error updating contact number:', error);
        }

        //for img
        try {
            if (!jobSeekerIMG) {
                console.error('No image found for upload');
                return;
            }
        
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token not found');
                return;
            }
        
            const formData = new FormData();
            formData.append('profile_image', jobSeekerIMG);
        
            const response = await http.post('job-seeker/updateImg', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
        
            if (response.status === 200) {
                setSuccess(response.data.message);
                setUpdating(false);
                setSuccess(true);
                setTimeout(() => setSuccess(false), 3000);
                setEditMode({ 
                    firstName: false,
                    lastName: false,
                    profession: false,
                    dob: false,
                    email: false,
                    nic: false,
                    mobile: false, 
                });
                
            } else {
                console.error('Failed to update image:', response.data.message);
                // Handle other status codes if needed
            }
        } catch (error) {
            console.error('An error occurred during image upload:', error);
            // Handle specific error cases as needed
        }
    };


    // for img upload
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (!file) return; // Handle no file selected
        if (file.size > 1024 * 1024) {
            setJobError(true)
            return;
        }
        setJobSeekerIMG(file);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const fileInputRef = React.createRef(null);

    return (
        <div>
            <Nav />
            <div className='dashboard-main'>
                <div className='dashboard-profile'>
                    
                    <div className='dashboard-profile-photo'>
                        {jobSeekerData && ( 
                            <h1 className='welcome-title'>Welcome,{jobSeekerData.job_seeker.seeker_first_name}</h1>
                        )}

                            <div className='dashboard-profile-center'>  
                                <div className='profile-icon-container'>

                                    {jobSeekerIMG && typeof jobSeekerIMG === 'object' ? (
                                        <img
                                        src={URL.createObjectURL(jobSeekerIMG)}
                                        alt="Job preview"
                                        className="profile-pic"
                                        onError={(e) => {
                                            e.target.style.display = 'none'; // Hide image if error occurs
                                            alert('Error creating image preview.');
                                        }}
                                        />
                                    ) : (
                                        <img
                                        src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${jobSeekerIMG}`}
                                        alt=""
                                        className="profile-pic"
                                        />
                                    )}

                                </div>
                                <label className="pic-add-button" htmlFor="profile-picture-input">
                                    <FontAwesomeIcon icon={faCirclePlus} onClick={handleButtonClick} />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className='form-input jobadd-form'
                                        onChange={handleImageChange}
                                        required
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                    />
                                </label>
                                
                            </div>
                        
                            
                        <form className='dashboard-form'>
                            <div className='dashboard-div1'>
                                {jobSeekerData && (
                                    <div className='input-div'>
                                        <label className='dashboard-labels'>First name</label><br />
                                        <div className='dashboard-text'>
                                            <input 
                                                id="seeker_first_name" 
                                                className='dashboard-textfield' 
                                                type="text"
                                                maxLength='24' 
                                                defaultValue={jobSeekerData.job_seeker.seeker_first_name}
                                                readOnly={!editMode.firstName} 
                                            />
                                            {!editMode.firstName ? (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('firstName')}
                                                    
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('firstName')}
                                                    fade
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}                           
                                {jobSeekerData && (
                                    <div className='input-div'>
                                        <label className='dashboard-labels'>Last name</label><br />
                                        <div className='dashboard-text'>
                                            <input 
                                                id="seeker_last_name" 
                                                className='dashboard-textfield' 
                                                type="text" 
                                                maxLength='24'
                                                defaultValue={jobSeekerData.job_seeker.seeker_last_name} 
                                                readOnly={!editMode.lastName}
                                            />
                                        {!editMode.lastName ? (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('lastName')}
                                                    
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('lastName')}
                                                    fade
                                                />
                                            )}
                                        </div>
                                    </div>
                                   
                            )}
                            </div>            
                            {jobSeekerData && (
                                <div className='input-div'>
                                    <label className='dashboard-labels'>Email</label><br />
                                    <div className='dashboard-text'>
                                        
                                        <input
                                            id='email'
                                            className='dashboard-textfield1' 
                                            type="text" 
                                            maxLength='60'
                                            defaultValue={jobSeekerData.email} 
                                            readOnly={!editMode.email}
                                        />
                                        {!editMode.email ? (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('email')}
                                                    
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('email')}
                                                    fade
                                                />
                                            )}
                                    </div>
                                </div>
                            )}
                                   
                                
                            
                            <div className='dashboard-div1'>
                                
                                    {jobSeekerData && (
                                        <div className='input-div'>
                                    <label className='dashboard-labels'>NIC</label><br />
                                    <div className='dashboard-text'>
                                            <input 
                                            id='nic'
                                            className='dashboard-textfield' 
                                            type="text" 
                                            maxLength='24'
                                            defaultValue={jobSeekerData.nic} 
                                            readOnly={!editMode.nic} 
                                        />
                                       {!editMode.nic ? (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('nic')}
                                                    
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('nic')}
                                                    fade
                                                />
                                            )}
                                    </div>
                                </div> 
                                     )}   
                                       
                                
                                    {jobSeekerData && (
                                        <div className='input-div'>
                                    <label className='dashboard-labels'>Mobile</label><br />
                                    <div className='dashboard-text'>
                                            <input 
                                                id="mobile" 
                                                className='dashboard-textfield' 
                                                type="text" 
                                                maxLength='10'
                                                defaultValue={jobSeekerData.contact[0].contact_number}
                                                readOnly={!editMode.mobile} 
                                            />
                                         {!editMode.mobile ? (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('mobile')}
                                                    
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('mobile')}
                                                    fade
                                                />
                                            )}
                                    </div>
                                </div>   
                            )}
                                        
                            </div>
                            <div className='dashboard-div1'>
                                
                                        {jobSeekerData && (
                                            <div className='input-div'>
                                    <label className='dashboard-labels'>Profession</label><br />
                                    <div className='dashboard-text'>
                                            <input 
                                                id="profession" 
                                                className='dashboard-textfield' 
                                                type="text" 
                                                maxLength='24'
                                                defaultValue={jobSeekerData.job_seeker.profession}
                                                readOnly={!editMode.profession} 
                                            />
                                            {!editMode.profession ? (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('profession')}
                                                    
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('profession')}
                                                    fade
                                                />
                                            )}
                                    </div>
                                </div>
                                        )}
                                        
                                
                                        {jobSeekerData && (
                                            <div className='input-div'>
                                    <label className='dashboard-labels'>Date Of Birth</label><br />
                                    <div className='dashboard-text'>
                                            <input 
                                                id="seeker_DOB" 
                                                className='dashboard-textfield' 
                                                type="date" 
                                                defaultValue={jobSeekerData.job_seeker.seeker_DOB}
                                                readOnly={!editMode.dob} 
                                            />
                                            {!editMode.dob ? (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('dob')}
                                                    
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faSquarePen}
                                                    style={{ color: "#8F6BD7" }}
                                                    onClick={() => toggleEditMode('dob')}
                                                    fade
                                                />
                                            )}
                                    </div>
                                </div>
                                        )}
                                        
                            </div>
                            <div className='dashboard-button'>

                                <button className='dashboard-button-p' onClick={handleUpdateProfile}>
                                    {updating ? 'Updating...' : 'Update'}
                                </button>  

                                <button className='dashboard-button-p' onClick={handleLogout}>Logout</button>

                                {jobError && <p className="img-error-massage">
                                    Image too big (1024 KB limit).<br/>
                                    Please pick a smaller one.              
                                </p>}
                            </div>
                            <div className="dash-msg-container">
                                {error && <p className="error-message">{error}</p>}
                                {success && <p className="success-message-dash">Profile Updated Successfully</p>}
                                
                            </div>
                        </form>
                    </div>
                </div>     
            </div>
            <Footer />
        </div>
    );
};

export default  SeekerDashboard;