import React, { useEffect, useState } from 'react';
import './Home.css';
import Nav from '../Components/Nav';
import SearchIcon from '../Assets/searchIcon.svg';
import ArrowIcon from '../Assets/Arrow.svg';
import CatagoryCard from './CatagoryCard';
import GirlImg from '../Assets/girl.png';
import Footer from '../Components/Footer';
import { NavLink, useNavigate , Link} from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import loadingAnimation from '../Assets/Animation - 1713718378766.json';
import Lottie from 'react-lottie';
import LocationIcon from '../Assets/location_icon.png'
import http from '../utils/http';


const Home = () => {
    useEffect(() =>{
        window.scrollTo(0, 0)
    }, [])

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // const fetchSearchResults = async () => {
    //     try {
    //         const response = await axios.get(`https://athwala-backend.api-softbyte.click/api/job/search-all?query=${searchQuery}`);
    //         console.log("Response:", response); // Log response data to inspect
    //         setSearchResults(response.data.jobs || []); // Make sure to handle undefined response.data.jobs
    //     } catch (error) {
    //         console.error('Error fetching search results:', error);
    //     }
    // };
    
    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    //         fetchSearchResults();
    //     }, 1500);
        
    //     return () => clearTimeout(delayDebounceFn);
    // }, [searchQuery, fetchSearchResults]); // Only run the effect if searchQuery changes

    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                setIsLoading(true); // Set loading status to true before fetching data
                const response = await http.get(`job/search-all?query=${searchQuery}`);
                console.log("Response:", response);
                // Filter out inactive jobs from the response data
                const activeJobs = response.data.jobs.filter(job => job.job_status === "Active");
                setSearchResults(activeJobs);
            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                setIsLoading(false); // Set loading status to false after fetching data
            }
        };
        
        const delayDebounceFn = setTimeout(() => {
            fetchSearchResults();
        }, 100);
        
        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery]); // Only run the effect if searchQuery changes

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    }

    const handleShowMore = () => {
        navigate('/jobs', { state: { searchQuery } });
    }

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: loadingAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5, // Show 3 cards horizontally on larger screens
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3.5,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.5, // Show 2 cards on screens up to 1024px
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 1.5,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1, // Show 1 card on screens up to 600px
                }
            }
        ],
        draggable: true,
        swipeToSlide: true,
        centerMode: false,
        centerPadding: "20px",
        // centerPadding: "100px",
        // Change dots colors
        
    };
    

  return (
    <div className='hero-section'>
        <Nav />
        <div className='hero-container'>
            <h1 className='hero-header'>Find <span className='text-highlight'>quick</span> jobs</h1>
            <h4 className='hero-subheader'>Browse through a variety of freelance opportunities</h4>
            <div className='search-container'>
                <div className='search-icon'>
                    <img src={SearchIcon} alt='Search' />
                </div>
                <input 
                    type="text" 
                    className='search-form' 
                    placeholder='Search Jobs'
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                />
            </div>
            {/* <NavLink to="/register/employee">
                <div className='header-btn-container'>
                    <button className='s-btn'>Become a Job Giver</button>
                    <img src={ArrowIcon} className='btn-img' alt='Arrow' />
                </div>
                <div className='header-btn-text'>
                    <p className='s-btn-text'>Need Work Done? Hire from Here</p>
                </div>
            </NavLink> */}
            <NavLink to="/register/employee" exact="true" activeClassName="active">
                <div className='header-btn-container'>
                    <button className='s-btn'>Become a Job Giver</button>
                    <img src={ArrowIcon} className='btn-img' alt='Arrow' />
                    
                </div>
            </NavLink> 
                <div className='header-btn-text'>
                    <p className='s-btn-text'>Need Work Done? Hire from Here</p>
                </div>
            
            {/* <Slider {...settings}>
            {searchResults.length > 0 && searchResults.map((job, index) => {
                const date = new Date(job.created_at);
                const formattedDate = date.toLocaleDateString();
                return (
                    <div key={index} className='search-result-wrapper'>
                        <div className='search-result-box'>
                            <div className='job-image'>
                                <img src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${job.image.image_url}`} alt='Job' />
                            </div>
                            <div className='job-details'>
                                <div className='job-giver'>
                                    <img src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${job.job_giver.user.images.image_url}`} alt='Profile' />
                                    <div className='job-giver-details'>
                                        <p>{job.job_giver.user.username}</p>
                                        <div className='job-giver-details-pro'>
                                            <p>{job.job_giver.profession}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className='job-info'>
                                    <h3>{job.job_title}</h3>
                                    <p><b>Posted Date:</b> {formattedDate}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            </Slider> */}
            {isLoading ? (
                <div>
                    <Lottie options={defaultOptions} height={200} width={200} />
                </div>
            ) : (
                <>
                    <Slider {...settings}>

                        {searchResults.length > 0 && searchResults.map((job, index) => {
                            const date = new Date(job.created_at);
                            const formattedDate = date.toLocaleDateString();
                            return (
                                <div key={index} className='search-result-wrapper'>
                                    <div className='search-result-box'>
                                        <div className='job-image'>
                                            <img src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${job.image.image_url}`} alt='Job' />
                                        </div>
                                        <Link to={`/job/${job.job_id}`}>
                                        <div className='job-details'>
                                            <div className='job-giver'>
                                                <img src={`https://athwala-cloud.s3.ap-southeast-1.amazonaws.com/${job.job_giver.user.images.image_url}`} alt='Profile' />
                                                <div className='job-giver-details'>
                                                    <p>{job.job_giver.user.username}</p>
                                                    <div className='job-giver-details-pro'>
                                                        <p>{job.job_giver.profession}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className='job-info'>
                                                <h3>{job.job_title}</h3>
                                                <div className='location-with-icon'>
                                                    <img src={LocationIcon} alt="" />
                                                    <p>{job.district.district_name}, {job.area.area_name}</p></div>
                                                <div className='payout-and-date'>
                                                    <p>
                                                        <b>
                                                        {job.payment === 'Negotiable' ? job.payment : `Rs.${job.payment}`}
                                                        </b>
                                                    </p>
                                                    <p>{formattedDate}</p>
                                                    {/* <p><b>Posted Date:</b> {formattedDate}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                    <div className='show-more-btn-section'> 
                        <button className='show-more-btn' onClick={handleShowMore}>Show More</button>
                    </div>
                </>
            )}
            
        </div>
        {/* {searchResults.length > 0 && (
            <div className='search-results'>
                <h2>Search Results</h2>
                <div className='search-results-container'>
                    {searchResults.map((job, index) => (
                        <div key={index} className='search-result-box'>
                            <h3>{job.job_title}</h3>
                            <p>Posted Date: {job.created_at}</p>
                            <p>Job Giver Name: {job.job_giver.giver_first_name} {job.job_giver.giver_last_name}</p>
                            {job.job_giver.user && (
                                <img src={job.job_giver.user.images.image_url} alt='Job Giver' />
                            )}
                        </div>
                    ))}
                </div>
                <button className='show-more-btn' onClick={handleShowMore}>Show More</button>
            </div>
        )} */}
        <CatagoryCard />
        <div className='about-container'>
            <img src={GirlImg}  className='about-girl' alt='Girl' />
            <div className='about-section'>
                <h2 className='about-header'>We'll help you <span className='text-highlight'>grow</span></h2>
                <p className='about-subheader'>Discover opportunities to develop your skills and earn money</p>
                <NavLink to="/about"><button className='explore-btn'>Explore</button></NavLink>
            </div>
        </div>

        <div className='about-container-mobile'>
            <h2 className='about-header-mobile'>We'll help you <span className='text-highlight'>grow</span></h2>
            <div className='about-inner-mobile'>
                <img src={GirlImg} alt='Girl' />
                <div className='about-section-mobile'>
                    <p className='about-subheader-mobile'>Discover opportunities to develop your skills and earn money</p>
                    <NavLink to="/about"><button className='about-mobile-btn'>Explore</button></NavLink>
                </div>
            </div>
        </div>

        <div className='colab-container'>
            <h2 className='colab-header'>In collaboration with</h2>
            <p className='colab-subheader'>SoftByte IT Solutions <br/>and<br/> Mr.Tech Private Limited.</p>
            <NavLink to="/about"><button className='btn'>Explore</button></NavLink>
        </div>
        <Footer />
    </div>
  );
};

export default Home;