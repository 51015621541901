import { BiHomeAlt2 } from "react-icons/bi";
import { PiAddressBookLight } from "react-icons/pi";
import { PiChatCircleBold } from "react-icons/pi";
import { MdOutlineWorkHistory } from "react-icons/md";
import { LuLogIn } from "react-icons/lu";
import { CgProfile } from "react-icons/cg";
import http from "../utils/http";

// Mock implementation of isLoggedIn (replace with your actual login check)
const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

// Mock implementation of navigateBasedOnRole (replace with your actual logic)
const navigateBasedOnRole = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found');
      return '/login';
    }

    const response = await http.get('profile', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    const role = response.data?.data?.user?.role_id;

    if (role === 2) {
      return '/dashboard';
    } else if (role === 3) {
      return '/dashboard/user';
    } else {
      console.error('Unknown role:', role);
      return '/login'; // Default to login if role is unknown
    }
  } catch (error) {
    console.error('An error occurred:', error);
    return '/login'; // Default to login if error occurs
  }
};

const loginHref = await navigateBasedOnRole();

export const routes = [
  {
    title: "Home",
    href: "/",
    Icon: BiHomeAlt2,
  },
  {
    title: "About",
    href: "/about",
    Icon: PiChatCircleBold,
  },
  {
    title: "Contact",
    href: "/contact",
    Icon: PiAddressBookLight,
  },
  {
    title: "Jobs",
    href: "/jobs",
    Icon: MdOutlineWorkHistory,
  },
  {
    title: isLoggedIn() ? "Dashboard" : "Login",
    href: loginHref,
    Icon: isLoggedIn() ? CgProfile : LuLogIn,
  },
];