import React from 'react';
import Login from './Login';

const ParentComponent = () => {
    const handleLogin = (token) => {
        // Handle login logic here (e.g., store the token)
        //console.log('Logged in with token:', token);
    };

    return (
        <div>
            <Login onLogin={handleLogin} />
        </div>
    );
};

export default ParentComponent;