import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faInfoCircle, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import './RegisterEmployee.css'; 
import { useNavigate } from 'react-router-dom';

const USER_REGEX = /^[A-Za-z][A-Za-z0-9_-]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,24}$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const RegisterEmployee = () => {
    useEffect(() =>{
        window.scrollTo(0, 0)
    }, [])

    const userRef = useRef(null);
    const errRef = useRef();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [firstNameCount, setFirstNameCount] = useState(0);
    const maxFirstNameLength = 24;

    const [lastName, setLastName] = useState('');
    const [lastNameCount, setLastNameCount] = useState(0);
    const maxLastNameLength = 24;

    const [nic, setNic] = useState('');
    const [nicCount, setNicCount] = useState(0);
    const maxNicLength = 24;

    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberCount, setPhoneNumberCount] = useState(0);
    const maxPhoneNumberLength = 10;

    const [profession, setProfession] = useState('');
    const [professionCount, setProfessionCount] = useState(0);
    const maxProfessionLength = 24;

    const [user, setUser] = useState('');
    const [userCount, setUserCount] = useState(0);
    const maxUserLength = 24;

    const [email, setEmail] = useState('');
    const [emailCount, setEmailCount] = useState(0);
    const maxEmailLength = 60;

    const [profilePictureError, setProfilePictureError] = useState('');

    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);
    const [userAvailable, setUserAvailable] = useState(true);
    const [emailAvailable, setEmailAvailable] = useState(true);

    const [pwd, setPwd] = useState('');
    const [pwdCount, setPwdCount] = useState(0);
    const maxPwdLength = 24;

    const [validPwd, setValidPwd] = useState(false);
    
    const [pwdFocus, setPwdFocus] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [matchPwdCount, setMatchPwdCount] = useState(0);
    const maxMatchPwdLength = 24;
    
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (userRef.current && userRef.current.focus) {
            userRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user]);

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd]);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd]);

    useEffect(() => {
        // Check availability when user changes username or email
        checkAvailability(user, email);
    }, [user, email]);


    // Characters Limitation and Validation Section

    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[a-zA-Z\s]*$/);

        if (isValid) {
            setFirstName(value);
            setFirstNameCount(value.length);
        }
    };

    const handleLastNameChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[a-zA-Z\s]*$/);

        if (isValid) {
            setLastName(value);
            setLastNameCount(value.length);
        }
    };

    const handleNicChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[a-zA-Z0-9]*$/);

        if (isValid) {
            setNic(value);
            setNicCount(value.length);
        }
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[0-9]*$/);

        if (isValid) {
            setPhoneNumber(value);
            setPhoneNumberCount(value.length);
        }
    }

    const handleProfessionChange = (e) => {
        const value = e.target.value;
        const isValid = validateInput(value, /^[a-zA-Z\s]*$/);

        if (isValid) {
            setProfession(value);
            setProfessionCount(value.length);
        }
    }

    const handleUsernameChange = (e) => {
        const value = e.target.value;

        if (value.length <= maxUserLength) {
            setUser(value);
            setUserCount(value.length);
            setErrMsg("");
        } else {
            setErrMsg("Username exceeds the character limit.");
        }
    }

    const handleEmailChange = (e) => {
        const value = e.target.value;

        if (value.length <= maxEmailLength) {
            setEmail(value);
            setEmailCount(value.length);
            setErrMsg("");
        } else {
            setErrMsg("Email exceeds the character limit.");
        }
    }

    const handlePwdChange =  (e) => {
        const value = e.target.value;

        if (value.length <= maxPwdLength) {
            setPwd(value);
            setPwdCount(value.length);
            setErrMsg("");
        } else {
            setErrMsg("Password exceeds the character limit.");
        }
    }

    const handleMatchPwdChange = (e) => {
        const value = e.target.value;

        if (value.length <= maxMatchPwdLength) {
            setMatchPwd(value);
            setMatchPwdCount(value.length);
            setErrMsg("");
        } else {
            setErrMsg("Password exceeds the character limit.");
        }
    }

    const validateInput = (value, regex) => {
        return regex.test(value);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const checkAvailability = async (username, email) => {
        // Validate email format
        const validEmailFormat = EMAIL_REGEX.test(email);
        if (!validEmailFormat) {
            setEmailAvailable(false);
            return;
        }

        // Your domain validation logic here
        const validDomain = email.endsWith("@gmail.com") || email.endsWith("@example.com"); // Add more domains as needed
        if (!validDomain) {
            setEmailAvailable(false);
            return;
        }
    
        try {
            const response = await axios.get(
                `https://athwala-backend.api-softbyte.click/api/job-giver/check-username?username=${username}&email=${email}`
            );
            setUserAvailable(response.data.username_available);
            setEmailAvailable(response.data.email_available);
        } catch (error) {
            console.error("Error checking availability:", error);
            setUserAvailable(false);
            setEmailAvailable(false);
        }
    };

    
    // Form-Data Testing
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validate all fields before submitting
        const validUsername = USER_REGEX.test(user);
        const validPassword = PWD_REGEX.test(pwd);
        const validConfirmPassword = pwd === matchPwd;
    
        if (!validUsername || !validPassword || !validConfirmPassword) {
            setErrMsg("Invalid Entry");
            return;
        }
        setSubmitting(true)
        // Create a new FormData object
        const formData = new FormData();
    
        // Append fields to the FormData object
        formData.append('username', user);
        formData.append('password', pwd);
        formData.append('email', email);
        formData.append('giver_first_name', e.target.elements.firstName.value);
        formData.append('giver_last_name', e.target.elements.lastName.value);
        formData.append('nic', e.target.elements.nic.value);
        formData.append('contact_number[]', e.target.elements.phoneNumber.value);
        formData.append('giver_DOB', e.target.elements.dateOfBirth.value);
        formData.append('profession', e.target.elements.profession.value);
        formData.append('profile_image', profilePicture, `profile.${profilePicture.type.split('/')[1]}`);
    
        try {
            // Send the FormData object in the POST request
            await axios.post('https://athwala-backend.api-softbyte.click/api/job-giver/add', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setSuccess(true);
            setSubmitting(false)
            setUser('');
            setEmail('');
            setPwd('');
            setMatchPwd('');
            await new Promise(resolve => setTimeout(resolve, 2000));
            navigate("/login");
            
        } catch (err) {
            // Handle errors
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username or Email Taken');
            } else {
                setErrMsg('Registration Failed')
                console.log(err);
            }
            if (errRef.current && errRef.current.focus) {
                errRef.current.focus();
            }
        }
    };

    //new profile picture 
    const [profilePicture, setProfilePicture] = useState(null);
    const [jobError, setJobError] = useState(false);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
    
        if (!file) return; // Handle no file selected
    
        if (file.size > 1024 * 1024) {
            setJobError(true)
          return;
        }
    
        setProfilePicture(file);
      };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const fileInputRef = React.createRef(null);

    useEffect(() => {
        if (jobError) {
            const timeoutId = setTimeout(() => setJobError(false), 3400);
            return () => clearTimeout(timeoutId); // Clear timeout on unmount
        }
    }, [jobError]);
    

    return (
        <div className="reg-main">
            <div className="login">
                <h1 className='employee_title'>Registration</h1>
                <h3 className='sub_employee_title'>Let’s find you some <span className='highlight'>employees.</span></h3>
                
                <form onSubmit={handleSubmit}>
                    {/* Profile picture upload */}
                    <div className="profile-pic-container-seeker">
                        {profilePicture && (
                                <img
                                src={URL.createObjectURL(profilePicture)}
                                alt="Profile Pic"
                                className="profile-pic"
                                onError={(e) => {
                                    e.target.style.display = 'none'; // Hide image if error occurs
                                    alert('Error creating image preview.');
                                }}
                                />
                            )}
                            
                            <button 
                                type="button" 
                                className="custom-file-button mb" 
                                onClick={handleButtonClick}
                            >
                                Choose a Profile Picture
                            </button>

                            <input
                                type="file"
                                accept="image/*"
                                className='form-input'
                                onChange={handleImageChange}
                                required
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />

                            {jobError && <p className="pic-error-massage">
                                Image size exceeds limit (1024 KB).<br/>
                                Please choose a smaller image.               
                            </p>}
                    </div>
                    
                    <div className='input-label-with-limit'>
                        <label htmlFor="username" className='labels'>
                            Username :
                            <FontAwesomeIcon icon={faCheck} className={(validName && userAvailable) ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={(validName && !userAvailable) || !user ? "invalid" : "hide"} />
                        </label>
                        <p className="character-limit-text">{userCount}/{maxUserLength}</p>
                    </div>
                    <input
                        type="text"
                        id="username"
                        ref={userRef}
                        autoComplete="off"
                        value={user}
                        onChange={handleUsernameChange}
                        required
                        aria-invalid={validName ? "false" : "true"}
                        aria-describedby="uidnote"
                        onFocus={() => setUserFocus(true)}
                        onBlur={() => setUserFocus(false)}
                        className='text_field margin30'
                        
                    />
                    {!userAvailable && <p className="error-message">Username is already taken.</p>}
                    <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        4 to 24 characters.<br />
                        Must begin with a letter.<br />
                        Letters, numbers, underscores, hyphens allowed.
                    </p>


                    <div className='input-label-with-limit'>
                        <label htmlFor="email" className='labels'>
                            Email :
                            <FontAwesomeIcon icon={faCheck} className={(emailAvailable) ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={(!emailAvailable) || !email ? "invalid" : "hide"} />
                        </label>
                        <p className="character-limit-text">{emailCount}/{maxEmailLength}</p>
                    </div>
                    <input
                        id="email"
                        className='text_field margin30'
                        type="email"
                        placeholder='Email'
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                    {!emailAvailable && <p className="error-message">Invalid email or already taken.</p>}
                    

                    <div className='input-label-with-limit'>
                        <label htmlFor="password" className='labels'>
                            Password : 
                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                        </label>
                        <p className="character-limit-text">{pwdCount}/{maxPwdLength}</p>
                    </div>
                    <div className="password-field">
                        <input
                            type={showPassword? "text" : "password"}
                            id="password"
                            onChange={handlePwdChange}
                            value={pwd}
                            required
                            aria-invalid={validPwd? "false" : "true"}
                            aria-describedby="pwdnote"
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}
                            className='text_field margin30'
                            autoComplete="current-password"
                        />
                        <FontAwesomeIcon
                            icon={showPassword? faEyeSlash : faEye}
                            className="toggle-password"
                            onClick={togglePasswordVisibility}
                        />
                    </div>
                    <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        8 to 24 characters.<br />
                        Must include uppercase and lowercase letters and a number.<br />
                        Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                    </p>


                    <div className='input-label-with-limit'>
                        <label htmlFor="confirm_pwd" className='labels'>
                            Confirm Password :
                            <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                        </label>
                        <p className="character-limit-text">{matchPwdCount}/{maxMatchPwdLength}</p>
                    </div>
                    <div className="password-field">
                        <input
                            type={showConfirmPassword? "text" : "password"}
                            id="confirm_pwd"
                            onChange={handleMatchPwdChange}
                            value={matchPwd}
                            required
                            aria-invalid={validMatch? "false" : "true"}
                            aria-describedby="confirmnote"
                            onFocus={() => setMatchFocus(true)}
                            onBlur={() => setMatchFocus(false)}
                            className='text_field margin30'
                            autoComplete="current-password"
                        />
                        <FontAwesomeIcon
                            icon={showConfirmPassword? faEyeSlash : faEye}
                            className="toggle-password"
                            onClick={toggleConfirmPasswordVisibility}
                        />
                    </div>
                    <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        Must match the first password input field.
                    </p>


                    <hr className='hr'></hr>

                    <div className='input-label-with-limit'>
                        <label htmlFor="firstName" className='labels'>First Name :</label><br />
                        <p className="character-limit-text">{firstNameCount}/{maxFirstNameLength}</p>
                    </div>
                    <input 
                        id="firstName" 
                        className='text_field margin30' 
                        type="text" 
                        placeholder='First Name'
                        onChange={handleFirstNameChange}
                        maxLength={maxFirstNameLength}
                        value={firstName}
                        required
                    ></input><br />

                    <div className='input-label-with-limit'>
                        <label htmlFor="lastName" className='labels'>Last Name :</label><br />
                        <p className="character-limit-text">{lastNameCount}/{maxLastNameLength}</p>
                    </div>
                    <input 
                        id="lastName" 
                        className='text_field margin30' 
                        type="text" 
                        placeholder='Last Name'
                        onChange={handleLastNameChange}
                        maxLength={maxLastNameLength}
                        value={lastName}
                    ></input><br />

                    <div className='input-label-with-limit'>
                        <label htmlFor="NIC" className='labels'>NIC :</label><br />
                        <p className="character-limit-text">{nicCount}/{maxNicLength}</p>
                    </div>
                    <input 
                        id="nic" 
                        className='text_field margin30' 
                        type="text" 
                        placeholder='NIC'
                        onChange={handleNicChange}
                        maxLength={maxNicLength}
                        value={nic}
                    ></input><br />

                    <div className='input-label-with-limit'>
                        <label htmlFor="phoneNumber" className='labels'>Phone number :</label><br />
                        <p className="character-limit-text">{phoneNumberCount}/{maxPhoneNumberLength}</p>
                    </div>
                    <input 
                        id="phoneNumber" 
                        className='text_field margin30' 
                        type="text" 
                        placeholder='07X XXXX XXX'
                        onChange={handlePhoneNumberChange}
                        maxLength={maxPhoneNumberLength}
                        value={phoneNumber}
                    ></input><br />

                    <label htmlFor="dateOfBirth" className='labels'>Date of birth :</label><br />
                    <input id="dateOfBirth" className='text_field margin30 date-input' type="date" placeholder='YYYY / MM / DD'></input><br />

                    <hr className='hr'></hr>

                    <div className='input-label-with-limit'>
                        <label htmlFor="profession" className='labels'>Profession :</label><br />
                        <p className="character-limit-text">{professionCount}/{maxProfessionLength}</p>
                    </div>
                    <input 
                        id="profession" 
                        className='text_field margin30' 
                        type="text" 
                        placeholder='Profession'
                        onChange={handleProfessionChange}
                        maxLength={maxProfessionLength}
                        value={profession}
                    ></input><br />

                    
                    <button 
                        className="login_button"
                        disabled={!validName || !validPwd || !validMatch || !userAvailable || profilePicture === null}>
                        {submitting ? 'Submitting...' : 'Submit'}
                    </button>
                    <div className="msg-container">
                        {errMsg && <p className="error-message">{errMsg}</p>}
                        {success && <p className="success-message">Registration Successful!</p>}
                        {profilePicture === null && <p className="error-message">Please upload a profile picture.</p>}
                    </div>
                </form>

            </div>
        </div>
    );
};

export default RegisterEmployee;