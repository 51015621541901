import React from 'react'
import './CatagoryCard.css'

const CatagoryCard = () => {
  return (
    <div className='catagory-container'>
        <div className='catagory-card-container card1'>
            <div className='catagory-btn'><h3>Type Setting</h3></div>
        </div>
        <div className='catagory-card-container card2'>
            <div className='catagory-btn'><h3>Poster installation</h3></div>
        </div>
        <div className='catagory-card-container card3'>
            <div className='catagory-btn'><h3>Paper marking</h3></div>
        </div>
    </div>
  )
}

export default CatagoryCard