import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import RegisterEmployee from './Pages/RegisterEmployee';
import RegisterJobSeeker from './Pages/RegisterJobSeeker';
import DashBoard from './Pages/DashBoard';
import JobDetails from './Pages/JobDetails';
import ParentComponent from './Pages/ParentComponent';
import JobAdd from './Components/JobAdd';
import Jobs from './Pages/Jobs'
import ContactUs from './Pages/ContactUs'
import About from './Pages/About';
import SeekerDashboard from './Pages/SeekerDashBoard';
import JobEdit from './Components/JobEdit';
import JobCard from './Pages/JobCard';


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<ContactUs/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/login' element={<ParentComponent />} />

        <Route path='/dashboard' element={<DashBoard />} /> 
        <Route path='/dashboard/user' element={< SeekerDashboard />} />

        <Route path='/register/employee' element={<RegisterEmployee />} />
        <Route path='/register/jobseeker' element={<RegisterJobSeeker />} />        
        
        <Route path='/jobadd' element={<JobAdd />} />
        <Route path='/jobedit/:jobId' element={<JobEdit />} />

        <Route path='/job/:jobId' element={<JobDetails/>} />
        <Route path='/jobs' element={<Jobs/>} />

        <Route path="*" element={<div>404 Not Found</div>} />
      </Routes>
    </Router>
  );
}

export default App;